<template>
  <div class="header">
    <slot name="left"></slot>
    <div v-if="logoutVisibility" class="header__logout">
      <Button
        :label="labels.logout"
        variant="btn-purple"
        :styles="{ padding: '8px 15px', fontWeight: 700 }"
        :action="logoutHd"
      />
    </div>
  </div>
</template>

<script>
import Button from "@/components/Buttons/Button";

import { mapActions } from "vuex";

export default {
  name: "Header",
  components: {
    Button,
  },
  data() {
    return {
      labels: {
        logout: "Logout",
      },
    };
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      logout: "logout",
    }),
    logoutHd() {
      this.logout().then((res) => {
        if (res.error) {
          this.addNotification({
            variant: "danger",
            msg: res.error,
            labels: this.labels,
          });
        }
        this.$router.push("/");
      });
    },
    logoutVisibility() {
      if (!this.$store.getters["user/get"].isAuthenticated) return false;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 55px;
  background-color: $white;
  border-bottom: 1px solid $light_gray;
  border-right: 1px solid $light_gray;
  width: 100%;
  padding: 0 15px;

  .left {
    margin-right: auto;
  }
}
</style>
