<template>
    <div class="notfount" :class="{ 'menu-active': menuOpened, 'is_dashboard': isDashboard }">
        <SideBarMenu/>
        <MobileHeader v-if="!isDashboard"/>

        <div class="notfount__content">
            <div class="notfount__content__header">
                <template v-if="isDashboard">
                    <Header/>
                    <PageHeader v-if="Object.keys(pageHeaderData).length > 1"/>
                </template>
                <template v-else>
                    <HeaderMenu/>
                </template>
            </div>

            <!-- show 404 -->
            <h1 :class="`${isDashboard ? 'content-padding' : 'client-content-padding'}` + ' display-1 error-code'">{{labels.errorCode}}: {{labels.legal_vacuum}}</h1>
            <Footer v-if="!isDashboard"/>
        </div>

        <CookiesFooter v-if="!isDashboard"/>
	</div>
</template>

<script>
import Header from "@/components/Header/Header";
import PageHeader from "@/components/Header/PageHeader";
import SideBarMenu from "@/components/Menu/SideBarMenu";
import HeaderMenu from "@/components/Menu/HeaderMenu";
import MobileHeader from "@/components/Header/MobileHeader";
import CookiesFooter from "@/components/Footer/CookiesFooter";
import Footer from "@/components/Footer/Footer";
import { mapState, mapGetters } from "vuex";
import { library } from "@fortawesome/fontawesome-svg-core";
import { customUser2 } from "@/assets/icons";
library.add( customUser2 );

export default {
    components: {
		SideBarMenu,
		HeaderMenu,
		MobileHeader,
		CookiesFooter,
		Footer,
		Header,
		PageHeader,
	},

    computed: {
        ...mapState( 'helper', {
			menuOpened: 'menuOpened',
			pageHeaderData: 'pageHeaderData',
		} ),
		...mapGetters('helper', ['labels']),
        isDashboard() {
            return this.$route.path.startsWith ('/dashboard');
        }
    },
    mounted() {
        setTimeout(()=>{
            this.$router.push('/login');
        }, 5000)
    }
}
</script>

<style lang="scss" scoped>

.notfount {
	display: flex;
	max-width: 1920px;
	margin: 0 auto;

	&__content {
		flex: 1 1 auto;
		-webkit-transition-duration: 0.3s;
		-moz-transition-duration: 0.3s;
		-o-transition-duration: 0.3s;
		transition-duration: 0.3s;
		max-width: 100vw;

		&__header {
			position: sticky;
			top: 0;
			z-index: 1000;
			-webkit-transition-duration: 0.3s;
			-moz-transition-duration: 0.3s;
			-o-transition-duration: 0.3s;
			transition-duration: 0.3s;
		}
	}

    &:not(.is_dashboard) {
        flex-flow: row no-wrap;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

        .notfount__content {
            position: relative;
            display: flex;
            flex-direction: column;
            max-width: 100%;

            &__header {
                position: static;
                top: 0;
                width: 100%;
            }

            @media (min-width: 768px) {
                max-width: calc( 100% - 50px );
            }

            @media (min-width: 1200px) {
                max-width: calc( 100% - 310px );
            }

            @media (min-width: 1600px) {
                max-width: calc( 100% - 340px );
            }
        }
    }

    .error-code {
        font-weight: 400;
    }
}
</style>